import { useEffect, useState } from 'react';
import styles from './ManagementTable.module.scss';
import { CheckBox } from '@ui/CheckBox/CheckBox';
import { Link } from 'react-router-dom';
import { Link as LinkIcon } from '@mui/icons-material';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { AppButton } from '../../../../../components/ui/AppButton/AppButton';
import { CopiedSnack } from '../../LinkInvite/CopiedSnack/CopiedSnack';
import { copyToClipboard } from '../../../../../lib/clipboard';

export const ManagementTable = ({
  tab,
  residents,
  onCheck,
  showPaymentLink,
}) => {
  const { community } = useSelector((state) => state.community);
  const [checked, setChecked] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const toggleChecked = (resident) => {
    setChecked((prev) => {
      const items = [...prev];
      const index = items.indexOf(resident);
      if (index > -1) {
        items.splice(index, 1);
      } else {
        items.push(resident);
      }
      return items;
    });
  };

  const getResidents = () => {
    const items = sortBy(residents, 'cardId').reverse();
    return items;
  };

  const copyPaymentLink = (resident) => {
    copyToClipboard(
      `${process.env.REACT_APP_UNAUTH_REDIRECT.split('/lead/')[0]}/pay/${
        resident.lastOrder.id
      }`
    );
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 3000);
  };

  useEffect(() => {
    onCheck(checked);
  }, [checked]);

  useEffect(() => {
    if (checkAll) {
      setChecked([...residents]);
    } else {
      setChecked([]);
    }
  }, [checkAll]);

  return (
    <>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <th>
                <CheckBox
                  checked={checkAll}
                  onChange={() => setCheckAll((prev) => !prev)}
                />
              </th>
              <th>Имя Фамилия</th>
              <th>Сообщество</th>
              <th>Почта</th>
              <th>Телеграм</th>
              {community.isPaid && showPaymentLink && <th></th>}
            </tr>
          </thead>
          <tbody>
            {getResidents().map((resident) => (
              <tr key={resident.cardId}>
                <td>
                  <CheckBox
                    checked={checked.includes(resident)}
                    onChange={() => toggleChecked(resident)}
                  />
                </td>
                <td>
                  <Link
                    to={`/club/${
                      resident.cardId
                    }/?redirectTo=${encodeURIComponent(
                      `/club/management/#${tab}`
                    )}`}
                  >
                    {resident.name}
                  </Link>
                </td>
                <td>{resident.communityTitle}</td>
                <td>{resident.email}</td>
                <td>{resident.telegram || 'Не указано'}</td>
                {community.isPaid && showPaymentLink && (
                  <td>
                    <AppButton
                      size="small"
                      startIcon={<LinkIcon />}
                      color="secondary"
                      onClick={() => copyPaymentLink(resident)}
                    >
                      Ссылка на оплату
                    </AppButton>
                  </td>
                )}
              </tr>
            ))}
            {!residents.length && (
              <tr>
                <td colSpan={5}>Ни одного резидента не найдено.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showCopied && <CopiedSnack />}
    </>
  );
};
