import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { startProgress } from '../../../store/common';
import { fetchCommunity } from '../../../store/community';
import { validatePageForm } from '../lib/validation';

export const communityPageSlice = createSlice({
  name: 'communityPage',
  initialState: {
    error: null,
    touched: {},
    formSubmitted: false,
    errors: {},
    loaded: false,
    saved: false,
    data: {
      title: null,
      avatar: null,
      slogan: null,
      website: null,
      telegram: null,
      about: null,
      team: [],
      bgColor: '#394ECA',
      payment: 'free',
      period: 1,
      amount: 10000,
      availableColors: [
        '#394ECA',
        '#2D77FC',
        '#8E33FF',
        '#FE3CA4',
        '#FE3C3C',
        '#FF6B00',
        '#FFAB00',
        '#22C55E',
        '#22BBC5',
        '#77798A',
      ],
    },
    unsavedData: {
      team: [],
    },
    isEditing: false,
    foundCards: [],
  },
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setIsEditing(state, action) {
      state.isEditing = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setImage(state, action) {
      state.data.avatar = action.payload;
    },
    setUnsavedData(state, action) {
      state.unsavedData = action.payload;
    },
    setLoaded(state, action) {
      state.loaded = action.payload;
    },
    setTouched(state, action) {
      state.touched = action.payload;
    },
    setFormSubmitted(state, action) {
      state.formSubmitted = action.payload;
    },
    setErrors(state, action) {
      state.errors = action.payload;
    },
    setSaved(state, action) {
      state.saved = action.payload;
    },
    setPeriodAndAmount(state, action) {
      state.data.period = action.payload.period;
      state.data.amount = action.payload.amount;
    },
  },
});

export const updateMemberInUnsaved =
  (oldMember, newMember) => (dispatch, getState) => {
    const data = getState().communityPage.unsavedData;
    const items = [...data.team];
    const index = items.indexOf(oldMember);
    if (index < 0) return;
    items.splice(index, 1, newMember);
    dispatch(
      communityPageSlice.actions.setUnsavedData({
        ...data,
        team: items,
      })
    );
  };

export const deleteMemberFromUnsaved = (cardId) => (dispatch, getState) => {
  const data = getState().communityPage.unsavedData;
  const { team } = data;
  const index = team.findIndex((item) => item.cardId === cardId);
  if (index < 0) return;
  const items = [...team];
  items.splice(index, 1);
  dispatch(
    communityPageSlice.actions.setUnsavedData({
      ...data,
      team: items,
    })
  );
};

export const deleteMemberFromData = (cardId) => (dispatch, getState) => {
  const data = getState().communityPage.data;
  const { team } = data;
  const index = team.findIndex((item) => item.cardId === cardId);
  if (index < 0) return;
  const items = [...team];
  items.splice(index, 1);
  dispatch(
    communityPageSlice.actions.setData({
      ...data,
      team: items,
    })
  );
};

export const startEdit = () => (dispatch, getState) => {
  dispatch(
    communityPageSlice.actions.setUnsavedData(getState().communityPage.data)
  );
  dispatch(communityPageSlice.actions.setIsEditing(true));
};

export const finishEdit = () => (dispatch) => {
  dispatch(communityPageSlice.actions.setIsEditing(false));
};

export const hideError = () => (dispatch) => {
  dispatch(communityPageSlice.actions.setError(null));
};

export const showError = (name) => (dispatch) => {
  dispatch(communityPageSlice.actions.setError(name));
  setTimeout(() => {
    dispatch(hideError());
  }, 5000);
};

export const updateCommunityImage = (data) => (dispatch) => {
  dispatch(communityPageSlice.actions.setImage(data));
};

export const updateCommunityPage = (newData) => (dispatch, getState) => {
  const data = getState().data;
  Object.assign(data, data);
  dispatch(communityPageSlice.actions.setData(data));
};

export const updateUnsavedData = (data) => (dispatch, getState) => {
  const unsavedData = {
    ...getState().communityPage.unsavedData,
    ...data,
  };
  dispatch(communityPageSlice.actions.setUnsavedData(unsavedData));
};

export const saveData = () => async (dispatch, getState) => {
  dispatch(startProgress());
  const state = getState();
  const { community } = state.community;
  const unsaved = state.communityPage.unsavedData;
  const data = state.communityPage.data;
  const newData = {
    ...data,
    ...unsaved,
    avatar: data.avatar,
  };
  dispatch(communityPageSlice.actions.setData(newData));
  const { id } = getState().community.community;
  const d = {
    title: newData.title || null,
    slogan: newData.slogan || null,
    website: newData.website || null,
    telegram: newData.telegram || null,
    about: newData.about || null,
    availableColors: newData.availableColors || [],
    bgColor: newData.bgColor || null,
    team: newData.team.map((item) => {
      const data = {
        cardId: item.cardId,
        description: item.description,
        showTelegram: item.showTelegram,
      };
      if (!data.description) {
        delete data.description;
      }
      return data;
    }),
  };
  await api.patch(`/communities/pages/${id}`, d);
  await api.patch(`/dashboard/communities/${community.id}`, {
    isPaid: newData.payment === 'paid',
    isPublic: newData.type === 'open',
  });
  if (newData.payment === 'paid') {
    await api.patch(`/dashboard/subscriptions/default`, {
      period: +newData.period,
      amount: +newData.amount,
    });
  }
  dispatch(fetchCommunity());
  dispatch(communityPageSlice.actions.setSaved(true));
};

export const fetchCommunityPage = () => async (dispatch, getState) => {
  dispatch(startProgress());
  const { id, title } = getState().community.community;
  let response = await api.get(`/communities/pages/${id}`);
  const data = {
    ...response.data,
    title: response.data.title || title,
    team: response.data.team || [],
  };
  dispatch(communityPageSlice.actions.setData(data));
  response = await api.get(`/dashboard/subscriptions/default`);
  dispatch(
    communityPageSlice.actions.setPeriodAndAmount({
      period: response.data.period,
      amount: response.data.amount,
    })
  );
  dispatch(communityPageSlice.actions.setLoaded(true));
};
