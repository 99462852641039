import { CommunityMenuIcon } from '../../../../icons/CommunityMenuIcon';
import { ConnectionIcon } from '../../../../icons/ConnectionIcon';
import styles from './ClubRoot.module.scss';
import { Heading } from '@layouts/Heading/Heading';
import { AppButton } from '@ui/AppButton/AppButton';
import { LibraryBooks, Link as LinkIcon } from '@mui/icons-material';
import { Tab, Tabs, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Add, Group, Person, Search, Settings } from '@mui/icons-material';
import { Input } from '@ui/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInitCards, fetchMoreCards } from '@features/club/store/club';
import { ResidentsCards } from '../ResidentsCards/ResidentsCards';
import { ResidentsTable } from '../ResidentsTable/ResidentsTable';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import { ViewSwitch } from '../ViewSwitch/ViewSwitch';
import { ClubFilters } from '../ClubFilters/ClubFilters';

export const ClubRoot = () => {
  const { community } = useSelector((state) => state.community);
  const [view, setView] = useState(
    () => localStorage.getItem('cards-view') || 'cards'
  );
  const [tab, setTab] = useState(0);
  const [isArchive, setIsArchive] = useState(false);
  const { total, page, cards, limit } = useSelector((state) => state.club);
  const [search, setSearch] = useState('');
  const [isBottom, setIsBottom] = useState(false);
  const { management } = useSelector((state) => state.club);
  const managementCount =
    management.toInvite.length +
    management.toVerify.length +
    management.toPay.length;
  const dispatch = useDispatch();
  const pageTitle = usePageTitle();
  const [filters, setFilters] = useState({
    city: null,
    community: null,
    role: null,
    status: null,
  });

  pageTitle('Резиденты');

  const navigate = useNavigate();

  const cardClicked = (card) => {
    navigate(`/club/${card.id}/`);
  };

  const fetchCards = () => {
    dispatch(fetchInitCards(search, isArchive, filters));
  };

  const onWindowScroll = (ev) => {
    const d = document.body.offsetHeight - window.innerHeight - window.scrollY;
    if (d > 200) {
      setIsBottom(false);
    } else {
      setIsBottom(true);
    }
  };

  const bottomChanged = () => {
    if (!isBottom) return;
    if (total < page * limit) return;
    dispatch(fetchMoreCards(search, isArchive, filters));
  };

  useEffect(() => {
    fetchCards();
    window.addEventListener('scroll', onWindowScroll);
    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, []);

  useEffect(() => {
    bottomChanged();
  }, [isBottom]);

  useEffect(() => {
    if (tab === 0) {
      setIsArchive(false);
    } else {
      setIsArchive(true);
    }
  }, [tab]);

  useEffect(() => {
    fetchCards();
  }, [isArchive, search, filters]);

  useEffect(() => {
    localStorage.setItem('cards-view', view);
  }, [view]);

  useEffect(() => {
    onWindowScroll();
  }, []);

  const filtersChanged = (filters) => {
    setFilters(filters);
  };

  return (
    <div className={styles.club}>
      <div className={styles.heading}>
        <Heading icon={CommunityMenuIcon} title="Управление сообществом">
          {!community.mainCommunityId && (
            <>
              <Link to="/club/related/">
                <AppButton color="secondary" startIcon={<ConnectionIcon />}>
                  Связанные сообщества
                </AppButton>
              </Link>
              &nbsp;
            </>
          )}
          <Link to="/club/dicts/spheres">
            <AppButton color="secondary" startIcon={<LibraryBooks />}>
              Справочники
            </AppButton>
          </Link>
          &nbsp;
          <Link to="/club/link-invite/">
            <AppButton color="primary" startIcon={<LinkIcon />}>
              Пригласить по ссылке
            </AppButton>
          </Link>
        </Heading>
      </div>

      <div className={styles.bar}>
        <div className={styles.search}>
          <Input
            placeholder="Поиск по резидентам"
            icon={Search}
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
          />
        </div>
        <div className={styles.add}>
          <Link to="/club/add/">
            <AppButton startIcon={<Add />} color="secondary">
              Добавить участника
            </AppButton>
          </Link>
        </div>
        <div className={styles.managementButton}>
          <Link to="/club/management/">
            <AppButton startIcon={<Settings />} color="secondary">
              <div className={styles.management}>
                <div className={styles.title}>Управление участниками</div>
                {!!managementCount && (
                  <div className={styles.count}>
                    <span>{managementCount}</span>
                  </div>
                )}
              </div>
            </AppButton>
          </Link>
        </div>
      </div>

      <div className={styles.filters}>
        <ClubFilters onChange={filtersChanged} isArchive={isArchive} />
      </div>

      <div className={styles.toolbar}>
        <div className={styles.tabs}>
          <Tabs value={tab} onChange={(ev, newValue) => setTab(newValue)}>
            <Tab label="Активные" />
            <Tab label="Архив" />
          </Tabs>
        </div>
        <div className={styles.switch}>
          <ViewSwitch view={view} onChange={setView} />
        </div>
      </div>

      <div className={styles.list}>
        {view === 'cards' && (
          <ResidentsCards cards={cards} onClick={cardClicked} />
        )}
        {view === 'table' && (
          <ResidentsTable cards={cards} onClick={cardClicked} />
        )}
      </div>
    </div>
  );
};
